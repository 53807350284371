import React from 'react'
import styles from './PressPack.module.css'
import downloadIcon from '../../../assets/download-icon.png';

const PressPack = (props) => {
  return (
    <div class={styles.container}>
      <a href={props.pressPackUrl} target="_new" className="press-pack-button"><img src={downloadIcon} className="download-icon" alt="" />Download Press Pack</a>
    </div>
  )
}

export default PressPack
